var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "개선 목록",
          tableId: "impr01",
          columns: _vm.gridImpr.columns,
          data: _vm.riskReduce.imprs,
          merge: _vm.gridImpr.merge,
          gridHeight: _vm.setheight,
        },
        on: { linkClick: _vm.linkClick },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "riskHazardPicture"
                  ? [
                      _c("q-icon", {
                        staticClass: "text-success",
                        staticStyle: { "font-size": "30px", cursor: "pointer" },
                        attrs: { name: "image" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openHazardPicture(props)
                          },
                        },
                      }),
                    ]
                  : col.name === "picture"
                  ? [
                      _c("q-icon", {
                        staticClass: "text-primary",
                        staticStyle: { "font-size": "30px", cursor: "pointer" },
                        attrs: { name: "image" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openImprPicture(props)
                          },
                        },
                      }),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }